<template>
    <AppPanel>
        <template #content>
            <div class="">
                <AppPaginatedGrid
                    ref="grid"
                    :service="service"
                    :actionItems="actionItems"
                    :filtrosExtras="filtrosExtras"
                    :hasFiltro="false"
                    newPath="/integracao-gupy/new"
                    editPath="/integracao-gupy/"
                    permission="integracao_gupy"
                    @onToggleMenu="onToggleMenu"
                    :loadingEffect="false"
                    subtitle="Integração Gupy"
                    tooltip="Sincronização com Gupy"
                >
                    <template #incluir-registro>
                        <div v-if="$checkPermission('integracao_gupy:criar')">
                            <Button label="Incluir" icon="pi pi-plus" class="p-button p-button-text mr-2" @click="openDialogIntegracaoGupy()" />
                        </div>
                    </template>
                    <template #anotherFilters>
                        <div class="flex flex-column p-fluid mt-2 md:mt-0 justify-items-between gap-2">
                            <Chips v-model="filtrosExtras.nomesFuncionarios" placeholder="Pesquisar" class="md:w-10" />
                            <div class="field-checkbox">
                                <Checkbox id="filtroPossuiErro" :binary="true" v-model="filtrosExtras.possuiErro" />
                                <label for="filtroPossuiErro">Mostrar apenas com erro</label>
                            </div>
                        </div>
                    </template>
                    <template #columns>
                        <Column field="id" header="Código" />
                        <Column field="gupyId" header="Código Gupy" />
                        <Column field="cliente.name" header="Cliente" />
                        <Column field="createdAt" header="Data Recebimento">
                            <template #body="{ data }">
                                {{ $filters.formatDateOnly(data.createdAt) }}
                            </template>
                        </Column>
                        <Column header="Funcionário">
                            <template #body="{ data }">
                                {{ data.funcionario ? data.funcionario?.name : data.nomeFuncionario }}
                            </template>
                        </Column>
                        <Column field="status" header="Status">
                            <template #body="{ data }">
                                <Tag
                                    v-tooltip="getTooltipError(data)"
                                    :style="tagStatusStyle(data.status)"
                                    :value="enumIntegracaoGupyStatus[data.status]"
                                />
                            </template>
                        </Column>
                    </template>
                </AppPaginatedGrid>
            </div>
            <DialogIntegracaoGupy ref="dialogIntegracaoGupy" @onSuccess="onSuccess" />
            <AppConfirmDialog
                ref="confirmDialog"
                :title="titleConfirmDialog"
                v-model:visible="showConfirmDialog"
                :loading="loadingConfirmar"
                @onClose="closeConfirmDialog"
                @onConfirm="onConfirmAction"
            />
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import { showError, showSuccess } from '../../utils/Toast';
import IntegracaoGupyStatusEnum from '../../enums/IntegracaoGupyStatusEnum';
import DialogIntegracaoGupy from './components/DialogIntegracaoGupy';
import AppConfirmDialog from '../../components/AppConfirmDialog';
import { getCurrentCustomers, getCurrentCompany } from '@/services/store';

export default {
    components: {
        DialogIntegracaoGupy,
        AppConfirmDialog
    },
    data() {
        return {
            service: null,
            loadingConfirmar: false,
            showConfirmDialog: false,
            titleConfirmDialog: '',
            enumIntegracaoGupyStatus: IntegracaoGupyStatusEnum,
            record: {},
            totais: {},
            filtrosExtras: {
                possuiErro: false,
                nomesFuncionarios: []
            },
            actionItems: [
                {
                    label: 'Visualizar',
                    icon: 'pi pi-eye',
                    disabled: () =>
                        !this.$checkPermission('integracao_gupy:view') ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.PROCESSANDO,
                    command: () => this.openDialogIntegracaoGupy(this.record)
                },
                {
                    label: 'Reprocessar',
                    icon: 'pi pi-replay',
                    disabled: () =>
                        !this.$checkPermission('integracao_gupy:reprocessar') ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.PROCESSANDO ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.SUCESSO ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.CANCELADO,
                    command: () => this.openConfirmDialog('reprocessar')
                },
                {
                    label: 'Cancelar',
                    icon: 'pi pi-times-circle',
                    disabled: () =>
                        !this.$checkPermission('integracao_gupy:cancelar') ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.PROCESSANDO ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.SUCESSO ||
                        IntegracaoGupyStatusEnum[this.record.status] === IntegracaoGupyStatusEnum.CANCELADO,
                    command: () => this.openConfirmDialog('cancelar')
                }
            ]
        };
    },
    created() {
        this.service = new BaseService('/v1/webservice/gupy/processamentos');
    },
    watch: {
        'filtrosExtras.possuiErro'() {
            this.$refs.grid.load();
        },
        'filtrosExtras.nomesFuncionarios'() {
            this.$refs.grid.load();
        },
        tenant() {
            this.$refs.grid.load();
        },
        currentCustomer() {
            this.$refs.grid.load();
        }
    },
    computed: {
        tenant() {
            return getCurrentCompany();
        },
        currentCustomer() {
            return getCurrentCustomers();
        }
    },
    methods: {
        getTooltipError(data) {
            if (IntegracaoGupyStatusEnum[data.status] === IntegracaoGupyStatusEnum.ERRO_CRIAR_FUNCIONARIO) {
                return `Não foi possivel criar o funcionário. Erro: ${data.erro}`;
            }

            if (IntegracaoGupyStatusEnum[data.status] === IntegracaoGupyStatusEnum.ERRO_CRIAR_AGENDAMENTO) {
                return `Não foi possivel criar o agendamento. Erro: ${data.erro}`;
            }

            return '';
        },
        tagStatusStyle(status) {
            if (
                IntegracaoGupyStatusEnum[status] === IntegracaoGupyStatusEnum.ERRO_CRIAR_FUNCIONARIO ||
                IntegracaoGupyStatusEnum[status] === IntegracaoGupyStatusEnum.ERRO_CRIAR_AGENDAMENTO
            ) {
                return {
                    'background-color': 'var(--pink-200)',
                    color: 'var(--pink-600)'
                };
            }

            if (IntegracaoGupyStatusEnum[status] === IntegracaoGupyStatusEnum.PROCESSANDO) {
                return {
                    'background-color': 'var(--orange-400)',
                    color: 'var(--orange-900)'
                };
            }

            if (IntegracaoGupyStatusEnum[status] === IntegracaoGupyStatusEnum.CANCELADO) {
                return {
                    'background-color': 'var(--surface-600)',
                    color: 'var(--surface-200)'
                };
            }

            return {
                'background-color': 'var(--green-300)',
                color: 'var(--green-900)'
            };
        },
        onToggleMenu(event, data) {
            this.record = data;
        },
        async onSuccess() {
            await this.$refs.grid.load();
        },
        openDialogIntegracaoGupy(record) {
            try {
                this.$refs.dialogIntegracaoGupy.open(record);
            } catch (error) {
                showError(this.$toast, error);
            }
        },
        openConfirmDialog(action) {
            this.titleConfirmDialog = `Deseja ${action} a integração a partir da etapa atual?`;
            this.showConfirmDialog = true;
            this.action = action;
        },
        closeConfirmDialog() {
            this.showConfirmDialog = false;
        },
        async onConfirmAction() {
            await this[this.action](this.record);
            this.showConfirmDialog = false;
            await this.$refs.grid.load();
        },
        async reprocessar(record) {
            try {
                this.loadingConfirmar = true;
                if (record.id) {
                    const form = this.getFormParaProcessar(record);
                    const service = new BaseService('/v1/webservice/gupy/reprocessar');
                    await service.save(form);
                    showSuccess(this.$toast, 'Registro processado com sucesso');
                }
                this.loadingConfirmar = false;
            } catch (error) {
                this.loadingConfirmar = false;
                showError(this.$toast, error);
            }
        },
        async cancelar(record) {
            try {
                this.loadingConfirmar = true;
                if (record.id) {
                    const service = new BaseService('/v1/webservice/gupy/cancelar');
                    await service.save(record);
                    showSuccess(this.$toast, 'Registro cancelado com sucesso');
                }
                this.loadingConfirmar = false;
            } catch (error) {
                this.loadingConfirmar = false;
                showError(this.$toast, error);
            }
            await this.$refs.grid.load();
        },
        getFormParaProcessar(record) {
            if (IntegracaoGupyStatusEnum[record.status] === IntegracaoGupyStatusEnum.ERRO_CRIAR_FUNCIONARIO) {
                const payloadIntegracao = JSON.parse(this.record.payloadIntegracao);
                return {
                    id: record.id,
                    empresa: payloadIntegracao?.companyName,
                    gupyId: payloadIntegracao?.data?.job?.id,
                    unidadeId: payloadIntegracao?.data?.job?.branch?.code,
                    unidadeNome: payloadIntegracao?.data?.job?.branch?.name,
                    cnpjUnidade: record?.cnpjUnidade,
                    setorId: payloadIntegracao?.data?.job?.department?.code,
                    setorNome: payloadIntegracao?.data?.job?.department?.name,
                    cargoId: payloadIntegracao?.data?.job?.role?.code,
                    cargoName: payloadIntegracao?.data?.job?.role?.name,
                    categoriaTrabalhador: payloadIntegracao?.data?.job?.type,
                    nomeFuncionario: `${payloadIntegracao?.data?.candidate?.name} ${payloadIntegracao?.data?.candidate?.lastName}`,
                    email: payloadIntegracao?.data?.candidate?.email,
                    cpf: payloadIntegracao?.data?.candidate.identificationDocument,
                    dataNascimento: payloadIntegracao?.data?.candidate?.birthdate,
                    cep: payloadIntegracao?.data?.candidate?.addressZipCode,
                    endereco: payloadIntegracao?.data?.candidate?.addressStreet,
                    enderecoNumero: payloadIntegracao?.data?.candidate?.addressNumber,
                    cidade: payloadIntegracao?.data?.candidate?.addressCity,
                    uf: payloadIntegracao?.data?.candidate?.addressStateShortName,
                    telefone: payloadIntegracao?.data?.candidate?.mobileNumber,
                    sexo: payloadIntegracao?.data?.candidate?.gender,
                    pcd: payloadIntegracao?.data?.candidate?.disabilities,
                    usuarioCriador: payloadIntegracao?.data?.user?.email,
                    usuarioName: payloadIntegracao?.data?.user?.name,
                    dataAdmissao: payloadIntegracao?.data?.application?.hiringDate
                };
            }

            return record;
        }
    }
};
</script>

<style scoped lang="scss"></style>
